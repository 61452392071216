import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import './index.less';
import toplogo from '../../static/toplogo.png'
import emitter from '../../unilt/emit'
import checkYs from '../../static/checkYs.png'
import local from '../../unilt/local'
import close from '../../static/close.png'
import Api from '../../unilt/api'

import titleboxbg from '../../static/hd/titleboxbg.png'
import titlebg from '../../static/hd/titlebg.png'
import close1 from '../../static/hd/close.png'


function TopHd() {
  let [menu1State, setmenu1State] = useState(false)
  let [loginShow, setloginShow] = useState(false)
  let [ys, setys] = useState(false)
  const user = useSelector(state => state.user)
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const renderRef = useRef(true)

  let [show, setshow] = useState(true)


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      emitter.addListener("opHdbox", () => {
        opennn()
      });
      return
    }
  }, [])

  
  const goPay = (() => {
    window.open(`https://discord.gg/JFKKTDBdH7`, "_blank",);
  })

  const close = (()=>{
    setshow(false)
  })

  const opennn = (()=>{
    setshow(true)
  })

  return (
    <div className="tophd">
        <img className='img1' src={titlebg} />
        <div className='p1'>{local['dctc']['p1'][language]}</div>
        <div className='btn1' onClick={()=>{goPay()}}>{local['dctc']['p2'][language]}</div>
        {/* {
          show && <div className='mask'>
          <div className='box'>
            <img className='imgg1' src={close1} onClick={()=>close()} ></img>
            <img className='imgg2' src={titleboxbg}></img>
            <pre className='pp1'>{local['dctc']['p3'][language]}</pre>
            <pre className='pp2'>{local['dctc']['p4'][language]}</pre>
            <div className='btnn1'onClick={()=>{goPay()}}>{local['dctc']['p5'][language]}</div>
          </div>
      </div>
        } */}

    </div>
  );
}

export default TopHd;
