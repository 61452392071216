import './index.less';
import toplogo from '../../static/toplogo.png'
import { Select } from 'antd';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import actoppc from '../../static/h5/actoppc.png'
import local from '../../unilt/local'
import close from '../../static/close.png'
import Api from '../../unilt/api'


function TopAcitveH5() {
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const hiddenres = useRef(true)
  let [ishidden, setishidden] = useState(false)


  useEffect(() => {
    if (hiddenres.current === true) {
      hiddenres.current = false
      if (localStorage.getItem('hidden') === 'ok') {
        setishidden(true)
      } else {
        setishidden(false)
      }
    }
  }, [])

  const goPage7 = (() => {
    window.open(`${Api.topUrl}/airdrop?lang=${language}`, "_blank",);
  })

  const hidden = (() => {
    setishidden(true)
    localStorage.setItem('hidden','ok')
  })


  return (
    <div className="topactiveh5" style={{display: ishidden ? 'none' : 'flex'}}>
      <img className='pic' src={actoppc} />
      <div className='pnew'>
        <div className='p1'>
          {local['topMode']['p106'][language]}
        </div>
        <div className='p2'>
          {local['topMode']['p107'][language]}
        </div>
      </div>
      <div className='btn' onClick={() => goPage7()}>{local['topMode']['p108'][language]}</div>
      <img className='closebtn' src={close}  onClick={()=>{hidden()}}/>
    </div>
  );
}

export default TopAcitveH5;
