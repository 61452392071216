
  const testApi = {
    path: 'http://inapitest.bbz.com/api/',
    wss:  'ws://13.214.192.187:9000',
    topUrl: 'https://tradertest.bbz.com'
  }
  
  const prodApi = {
    path: 'https://inapitest.bbz.com/api/',
    wss:  'wss://bbz.com/wss/',
    topUrl: 'https://tradertest.bbz.com'
  }
  
  let Api = testApi
  
  if (process.env.NODE_ENV === 'production') {
    Api = prodApi
  } else {
    Api = prodApi
  }
  
  export default Api
  
