
import './App.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo, useCallback } from 'react';
import BottomMode from './components/BottomMode';
import NewBottomModeH5 from './components/NewBottomModeH5';
import TopHd from './components/TopHd';
import TopMode from './components/TopMode';

import TopModeH5 from './components/TopModeH5';

import BasicRoute from './Router/Router'

import BasicRouteH5 from './Router/RouterH5'

import TopAcitve from './components/TopAcitve';
import TopAcitveH5 from './components/TopAcitveH5';
import { useLocation } from "react-router-dom";
import Adjust from '@adjustcom/adjust-web-sdk';
import { useSelector, useDispatch } from 'react-redux'
import { MyHead } from './components/PostCss'

function App() {
  const location = useLocation();
  const path = location.pathname;
  const renderRef = useRef(true)
  const user = useSelector(state => state.user)
  let [systemType, setsystemType] = useState('none')


  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight
  })

  const onResize = useCallback(() => {

    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })

  }, [])



  useEffect(() => {

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setsystemType('phone')
    } else {
      setsystemType('pc')
    }

    window.addEventListener('resize', onResize);
    return (() => {
      window.removeEventListener('resize', onResize)
    })

  }, []);


  console.log(user)
  return (
    <div>
      {
        systemType === 'pc' && <div className="App" style={{ transformOrigin: 'left top', transform: `scale(${size.width >= 1280 ? 1 : (size.width) / 1280})`, minHeight: size.width >= 1280 ? '100vh' : 128000 / (size.width) + 'vh', flex: size.width }}>
          <div className='app-content'>
            <TopAcitve />
            <TopMode />
            <div className='lineHeight'></div>
            <BasicRoute />
            <BottomMode />
          </div>
        </div>
      }
      {
        systemType === 'phone' && <div className="AppH5">
          <MyHead></MyHead>
          <div className='app-content'>
            <TopAcitveH5 />
            <TopModeH5 />
            <div className='lineHeight'></div>
            <BasicRouteH5 />
            <NewBottomModeH5 />
          </div>
        </div>
      }
    </div>

  );
}

export default App;
