import './index.less';
import { Carousel } from 'antd';
import 'swiper/less'
import local from '../../unilt/local'
import { useSelector, useDispatch } from 'react-redux'
import nftico1 from '../../static/nftico1.png'
import nftico2 from '../../static/nftico2.png'

import p2bg from '../../static/p2bg.png'

import p211 from '../../static/p211.png'
import p212 from '../../static/p212.png'
import p221 from '../../static/p221.png'
import p222 from '../../static/p222.png'
import p223 from '../../static/p223.png'
import p224 from '../../static/p224.png'
import p231 from '../../static/p231.png'
import p232 from '../../static/p232.png'


function Nft() {


  const language = useSelector(state => state.language)


  const goPay = (() => {
    window.open("https://opensea.io", "_blank",);
  })



  const goCl = (() => {
  })

  return (
    <div className="nft" onClick={() => goCl()}>
      <div className='mode1'>
        <div className='cont'>
          <img src={p2bg}  className='bg11' />
          <pre className='p1'>{local['newbb']['p1'][language]}</pre>
          <pre className='p2'>{local['newbb']['p2'][language]}</pre>
          <div className='deflatBtn'>{local['newbb']['p17'][language]}</div>
          {/* <div className='box'>
            <img  className='ico' src={p211} />
            <div className='rightbox'>
              <pre className='boxp1'>{local['newbb']['p3'][language]}</pre>
              <pre className='boxp2'>{local['newbb']['p4'][language]}</pre>
            </div>
          </div>
          <div className='box'>
            <img  className='ico' src={p212} />
            <div className='rightbox'>
              <pre className='boxp1'>{local['newbb']['p5'][language]}</pre>
              <pre className='boxp2'>{local['newbb']['p6'][language]}</pre>
            </div>
          </div> */}
        </div>
      </div>
      <div className='mode2'>
        <div className='cont'>
          <pre className='p1'>{local['newbb']['p7'][language]}</pre>
          <div className='box1'>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p221} />
                </div>
                <pre className='ip1'>{local['newbb']['p8'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p81'][language]}</pre>
            </div>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p222} />
                </div>
                <pre className='ip1'>{local['newbb']['p9'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p91'][language]}</pre>
            </div>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p223} />
                </div>
                <pre className='ip1'>{local['newbb']['p10'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p101'][language]}</pre>
            </div>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p224} />
                </div>
                <pre className='ip1'>{local['newbb']['p1z'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p1z1'][language]}</pre>
            </div>
          </div>
        </div>
      </div>
      <div className='mode2'>
        <div className='cont'>
          <pre className='p1'>{local['newbb']['p11'][language]}</pre>
          <pre className='p2'>{local['newbb']['p12'][language]}</pre>
          <div className='box'>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p231} />
                </div>
                <pre className='ip1'>{local['newbb']['p13'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p14'][language]}</pre>
            </div>
            <div className='item'>
                <div className='icobox'>
                    <img className='ico' src={p232} />
                </div>
                <pre className='ip1'>{local['newbb']['p15'][language]}</pre>
                <pre className='ip2'>{local['newbb']['p16'][language]}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nft;
