import React from 'react';
import PrivateRoute from '../components/PrivateRoute'
import {Routes, Route } from "react-router-dom";
import history from './browserHistory'
import routerList from './routerList'


const BasicRoute = () => (
    <Routes history={history} basename='/'>
        {
            routerList.map((routerItem,index)=>{
                return <Route exact key={'router' + index} path={"/" + routerItem.path} element={<routerItem.component/>} />
            })
        }
    </Routes>
);

export default BasicRoute;

