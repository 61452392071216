import './index.less';
import { useState, useEffect, useRef } from 'react'
import { Carousel } from 'antd';
import 'swiper/less'
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import local from '../../unilt/local'
import { useSelector, useDispatch } from 'react-redux'
import Api from '../../unilt/api'



import topbg from '../../static/hd/topbg.png'

import hd11 from '../../static/hd/hd11.png'
import hd12 from '../../static/hd/hd12.png'
import hd21 from '../../static/hd/hd21.png'
import hd22 from '../../static/hd/hd22.png'
import hd31 from '../../static/hd/hd31.png'
import hd32 from '../../static/hd/hd32.png'
import hd41 from '../../static/hd/hd41.png'
import hd42 from '../../static/hd/hd42.png'


import hdpt1 from '../../static/hd/hdpt1.png'
import hdpt2 from '../../static/hd/hdpt2.png'
import hdpt3 from '../../static/hd/hdpt3.png'
import hdpt4 from '../../static/hd/hdpt4.png'
import hdpt5 from '../../static/hd/hdpt5.png'
import hdpt6 from '../../static/hd/hdpt6.png'
import hdpt7 from '../../static/hd/hdpt7.png'
import hdpt8 from '../../static/hd/hdpt8.png'
import hdpt9 from '../../static/hd/hdpt9.png'
import hdpt10 from '../../static/hd/hdpt10.png'
import hdpt11 from '../../static/hd/hdpt11.png'



import stepimgbox1 from '../../static/hd/stepimgbox1.png'
import stepimgbox2 from '../../static/hd/stepimgbox2.png'
import stepimgbox3 from '../../static/hd/stepimgbox3.png'
import stepimgbox4 from '../../static/hd/stepimgbox4.png'


function Party() {

  const [getParams, setParam] = useSearchParams()
  const language = useSelector(state => state.language)

  let [tab, settab] = useState(1)

  const go1 = (() => {
    window.open(`${Api.topUrl}/faucet`, "_blank",);
  })

  const go2 = (() => {
    window.open(`${Api.topUrl}/`, "_blank",);
  })

  const go3 = (() => {
    window.open(`${Api.topUrl}/pools`, "_blank",);
  })

  const go4 = (() => {
    const bottombox = document.querySelector("#bottombox");
    bottombox.scrollIntoView({ behavior: "smooth" });
  })

  const golink = ((url) => {
    window.open(url, "_blank",);
  })


  return (
    <div className="partyh5">
      <div className='mode1'>
        <div className='cont'>
          <pre className='p1'>
            {local['party']['p1'][language]}
          </pre>
          <pre className='p2'>
            {local['party']['p2'][language]}
          </pre>
          <img src={topbg} className='img1' />
        </div>
      </div>
      <div className='mode2'>
        <div className='cont'>
          <pre className='title'>
            {local['party']['p3'][language]}
          </pre>
          <pre className='hp'>
            {local['party']['p4'][language]}
          </pre>
          <pre className='title'>
            {local['party']['p5'][language]}
          </pre>
          <pre className='hp1'>
            {local['party']['p6'][language]}
          </pre>
          <div className='item'>
            <div className='dot'></div>
            <div className='p1'>{local['party']['p7'][language]}</div>
          </div>
          <div className='item'>
            <div className='dot'></div>
            <div className='p1'>{local['party']['p8'][language]}</div>
          </div>
          <div className='item'>
            <div className='dot'></div>
            <div className='p1'>{local['party']['p9'][language]}</div>
          </div>
          <pre className='title1'>
            {local['party']['p10'][language]}
          </pre>
          <div className='stepbox'>
            <div className='line1'>
              <img className='num' src={tab === 1 ? hd12 : hd11} onClick={() => settab(1)} />
              <img className='num' src={tab === 2 ? hd22 : hd21} onClick={() => settab(2)} />
              <img className='num' src={tab === 3 ? hd32 : hd31} onClick={() => settab(3)} />
              <img className='num' src={tab === 4 ? hd42 : hd41} onClick={() => settab(4)} />
            </div>
            <div className='line2'>
              <div className={tab === 1 ? 'dot dota' : 'dot'} onClick={() => settab(1)}></div>
              <div className={tab === 2 ? 'dot dota' : 'dot'} onClick={() => settab(2)}></div>
              <div className={tab === 3 ? 'dot dota' : 'dot'} onClick={() => settab(3)}></div>
              <div className={tab === 4 ? 'dot dota' : 'dot'} onClick={() => settab(4)}></div>
              <div className='line'></div>
            </div>
            <div className='line3'>
              <div className='text' onClick={() => settab(1)}>{local['party']['p11'][language]}</div>
              <div className='text' onClick={() => settab(2)}>{local['party']['p12'][language]}</div>
              <div className='text' onClick={() => settab(3)}>{local['party']['p13'][language]}</div>
              <div className='text' onClick={() => settab(4)}>{local['party']['p14'][language]}</div>
            </div>
          </div>
          {tab === 1 && <img src={stepimgbox1} className='bigimg'></img>}
          {tab === 2 && <img src={stepimgbox2} className='bigimg'></img>}
          {tab === 3 && <img src={stepimgbox3} className='bigimg'></img>}
          {tab === 4 && <img src={stepimgbox4} className='bigimg'></img>}
          {tab === 1 && <div className='bigbtn' onClick={() => go1()}>{local['party']['p15'][language]}</div>}
          {tab === 2 && <div className='bigbtn' onClick={() => go2()}>{local['party']['p15'][language]}</div>}
          {tab === 3 && <div className='bigbtn' onClick={() => go3()}>{local['party']['p15'][language]}</div>}
          {tab === 4 && <div className='bigbtn' onClick={() => go4()}>{local['party']['p15'][language]}</div>}
          <pre className='title1'>
            {local['party']['p16'][language]}
          </pre>
          <div className='hzsbox'>
            <img src={hdpt1} onClick={() => golink('https://app.questn.com/quest/785390853728174433')} className='hzsimg'></img>
            <img src={hdpt2} onClick={() => golink('https://www.premint.xyz/bbz-trade-30-exclusive-whitelists/')} className='hzsimg'></img>
            <img src={hdpt3} onClick={() => golink('https://zealy.io/c/bbztrade/questboard/38aa4387-1a74-4c5c-929c-582b4b407a55')} className='hzsimg'></img>
            <img src={hdpt4} onClick={() => golink('https://taskon.xyz/campaign/detail/5987')} className='hzsimg'></img>
            <img src={hdpt5} onClick={() => golink('https://trantor.xyz/campaign/3663607103489')} className='hzsimg'></img>
            <img src={hdpt6} onClick={() => golink('https://denet.me/m/Ip92tr')} className='hzsimg'></img>
            <img src={hdpt7} onClick={() => golink('https://akiprotocol.io/airdrop/claim/bmXKqDmZBnBJztbCsF1x?ref=9a2b3494-6e36-4377-b09d-32a35408a5e0')} className='hzsimg'></img>
            <img src={hdpt8} onClick={() => golink('https://clique.social/provenance/bbz-trade-coming-30-exclusive-whitelists')} className='hzsimg'></img>
            <img src={hdpt9} onClick={() => golink('https://link3.to/bbz')} className='hzsimg'></img>
            <img src={hdpt10} onClick={() => golink('https://soquest.xyz/space/bbztrade/campaign/jNiLFDGdV4')} className='hzsimg'></img>
            <img src={hdpt11} onClick={() => golink('https://galxe.com/bbztrade/campaign/GCa3tUeDm3')} className='hzsimg'></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Party;
