import Home from  '../pages/home';
import Nft from '../pages/ntf';
import Docs from '../pages/docs';
import Party from '../pages/party';

const routerList = [
    {
        path: '',
        breadcrumbName:  '首页',
        component: Home,
        private: false
    },
    {
        path: '/balancerbuffer',
        breadcrumbName:  'NFT',
        component: Nft,
        private: false
    },
    {
        path: '/event',
        breadcrumbName:  'Party',
        component: Party,
        private: false
    }
]

export default routerList;