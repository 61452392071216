var local = {
    "topMode": {
        "nav1": {
            "zhcn": "App",
            "en": "App",
            "hi": "अनुप्रयोग",
        },
        "nav2": {
            "zhcn": "Balancer Buffer",
            "en": "Balancer Buffer",
            "hi": "बैलेंसर बफर",
        },
        "nav3": {
            "zhcn": "文檔",
            "en": "Docs",
            "hi": "डॉक्स",
        },
        "nav4": {
            "zhcn": "活動",
            "en": "Event",
            "hi": "आयोजन",
        },
        "btn1": {
            "zhcn": "啟動應用程序",
            "en": "Launch App",
            "hi": "ऐप लांच करें",
        },
        "boxtitle1": {
            "zhcn": "選擇語言",
            "en": "Select Language",
            "hi": "भाषा चुने",
        },
        "p106": {
            "zhcn": "快來領取盲盒",
            "en": "Get the Mystery Box",
            "hi": "ब्लाइंड बॉक्स प्राप्त करें",
        },
        "p107": {
            "zhcn": "和測試幣！",
            "en": "and test coins!",
            "hi": "और सिक्कों का परीक्षण करें!",
        },
        "p108": {
            "zhcn": "領取",
            "en": "Claim",
            "hi": "भाषा चुने",
        }
    },
    "bottombox": {
        "link1": {
            "zhcn": "條款及細則",
            "en": "Terms and Conditions",
            "hi": "नियम और शर्तें",
        },
        "link2": {
            "zhcn": "推薦條款",
            "en": "Referral Terms",
            "hi": "रेफरल शर्तें",
        },
        "link3": {
            "zhcn": "多媒體工具",
            "en": "Media Kit",
            "hi": "मीडिया किट",
        }
    },
    "home": {
        "p106": {
            "zhcn": "領取盲盒",
            "en": "Get the Mystery Box",
            "hi": "प्राप्त करें",
        },
        "m1p1": {
            "zhcn": "去中心化\n特殊期權交易平台",
            "en": "Decentralized \nSpecial Options Trading Platform",
            "hi": "विकेन्द्रीकृत \nविशेष विकल्प ट्रेडिंग प्लेटफार्म",
        },
        "m1b1": {
            "zhcn": "啟動應用程序",
            "en": "Launch App",
            "hi": "ऐप लांच करें",
        },
        "m1bp11": {
            "zhcn": "全民莊家",
            "en": "All people bookmakers",
            "hi": "सभी लोग सट्टेबाज हैं",
        },
        "m1bp12": {
            "zhcn": "任何人都可以擁有莊家優勢。",
            "en": "Anyone can have the house advantage.",
            "hi": "मकान का लाभ किसी को भी हो सकता है।",
        },
        "m1bp21": {
            "zhcn": "完全公開透明",
            "en": "Completely open and transparent",
            "hi": "बिल्कुल खुला और पारदर्शी",
        },
        "m1bp22": {
            "zhcn": "所有存款、取款、交易都在區塊鏈上完成。",
            "en": "All deposits, withdrawals, transactions \nare done on the blockchain.",
            "hi": "सभी जमा, निकासी, लेनदेन ब्लॉकचेन पर किए जाते हैं।",
        },
        "m1bp31": {
            "zhcn": "自主添加代幣",
            "en": "Add tokens independently",
            "hi": "स्वतंत्र रूप से टोकन जोड़ें",
        },
        "m1bp32": {
            "zhcn": "任何你喜歡的代幣，都可以添加成為交易標的。",
            "en": "Any token you like can be added as a transaction target.",
            "hi": "आपकी पसंद का कोई भी टोकन लेनदेन लक्ष्य के रूप में जोड़ा जा सकता है।",
        },
        "m2p1": {
            "zhcn": "我們的生態系統-BBZ代幣",
            "en": "Our Ecosystem - BBZ Token",
            "hi": "हमारा पारिस्थितिकी तंत्र - BBZ टोकन", 
        },
        "m2p2": {
            "zhcn": "BBZ是治理代幣，可以分配平台的手續費。",
            "en": "BBZ is a governance token that can distribute platform fees.",
            "hi": "BBZ एक गवर्नेंस टोकन है जो प्लेटफ़ॉर्म शुल्क वितरित कर सकता है।", 
        },
        "m2b1": {
            "zhcn": "啟動應用程序",
            "en": "Launch App",
            "hi": "ऐप लांच करें", 
        },
        "m2b2": {
            "zhcn": "即將推出",
            "en": "Coming soon",
            "hi": "जल्द आ रहा है", 
        },
        "m3p1": {
            "zhcn": "BBZ目前在BASE、BNB Chain上線",
            "en": "BBZ is currently live on BASE、BNB Chain",
            "hi": "BBZ वर्तमान में BASE、BNB Chain पर लाइव है",
        },
        "m3icop1": {
            "zhcn": "Arbitrum",
            "en": "Arbitrum",
            "hi": "Arbitrum",
        },
        "m3icop1t1": {
            "zhcn": "BBZ PRO Pass是參與BBZ治理的入場券，BBZ交易平台會以DAO的形式來完成自治。",
            "en": "BBZ PRO Pass is an admission ticket to participate in BBZ governance, and the platform will complete autonomy in the form of DAO.",
            "hi": "BBZ PRO पास BBZ गवर्नेंस में भाग लेने के लिए एक प्रवेश टिकट है, और प्लेटफ़ॉर्म DAO के रूप में पूर्ण स्वायत्तता प्राप्त करेगा।",
        },
        "m3icop1b1": {
            "zhcn": "啟動應用程序",
            "en": "Launch App",
            "hi": "ऐप लांच करें",
        },
        "m3icop1b2": {
            "zhcn": "了解更多",
            "en": "Read more",
            "hi": "और पढ़ें",
        },
        "m3icop2": {
            "zhcn": "BNB Chain",
            "en": "BNB Chain",
            "hi": "BNB Chain",
        },
        "m3icop2t1": {
            "zhcn": "BBZ是治理代幣，可以分配平台的手續費。 \n[即將推出]",
            "en": "BBZ is a governance token that can distribute platform fees. \n[coming soon]",
            "hi": "BBZ एक गवर्नेंस टोकन है जो प्लेटफ़ॉर्म शुल्क वितरित कर सकता है। \n[जल्द आ रहा है]",
        },
        "m3icop2b1": {
            "zhcn": "在Arbitrum購買",
            "en": "Buy on Arbitrum",
            "hi": "Arbitrum पर खरीदें",
        }
    },
    "nft": {
        "m1p1": {
            "zhcn": "BBZ PRO Pass",
            "en": "BBZ PRO Pass",
            "hi": "BBZ PRO पास",
        },
        "m1p2": {
            "zhcn": "成為 10,000 人中的一員，可以解鎖 BBZ 俱樂部的會員資格和其他取決\n於稀有性的獨家權益，請參閱下面的持有人利益。",
            "en": "Be one of 10,000 to unlock membership to the BBZ Club and other \n exclusive benefits depending on rarity, see holder benefits below.",
            "hi": "दुर्लभता के आधार पर BBZ क्लब की सदस्यता और अन्य विशेष लाभों को अनलॉक करने के लिए 10,000 में से एक बनें, धारक लाभ नीचे देखें।",
        },
        "m1b1": {
            "zhcn": "在OpenSea購買",
            "en": "Buy on OpenSea",
            "hi": "OpenSea पर खरीदें",
        },
        "m1bp1": {
            "zhcn": "正在進行！",
            "en": "In progress",
            "hi": "चालू",
        },
        "m1bp2": {
            "zhcn": "感謝大家成為 BBZ PRO Pass 持有者！尋找通行\n證？查看 OpenSea 上的二級列表！",
            "en": "Thank you all for being BBZ PRO Pass holders! Looking for a pass\n pass? Check out the secondary listings on OpenSea!",
            "hi": "BBZ PRO पास धारक होने के लिए आप सभी को धन्यवाद! पास\nपास खोज रहे हैं? OpenSea पर द्वितीयक सूची देखें!",
        },
        "m1bp3": {
            "zhcn": "剩餘供應",
            "en": "Remaining supply",
            "hi": "शेष आपूर्ति",
        },
        "m2p1": {
            "zhcn": "持有人的獨家權益",
            "en": "Exclusive benefits for holders",
            "hi": "धारकों के लिए विशेष लाभ",
            
        },
        "m2p2": {
            "zhcn": "作為 BBZ PRO Pass 持有人，可提審任意交易池進入官方白名單,\n參與投票決定BBZ平台收益的分配方式,決定BBZ平台未來的重大更新。",
            "en": "As a BBZ PRO Pass holder, you can submit any trading pool to enter the official white list,\n Participate in voting to determine the distribution method of BBZ platform revenue and determine the major updates of BBZ platform in the future.",
            "hi": "BBZ PRO पास धारक के रूप में, आप आधिकारिक श्वेत सूची में प्रवेश करने के लिए किसी भी ट्रेडिंग पूल को सबमिट कर सकते हैं, BBZ प्लेटफ़ॉर्म राजस्व की वितरण विधि निर्धारित करने और भविष्य में BBZ प्लेटफ़ॉर्म के प्रमुख अपडेट निर्धारित करने के लिए मतदान में भाग ले सकते हैं।",
        },
        "m2bp11": {
            "zhcn": "公開交易池",
            "en": "Public trading pool",
            "hi": "सार्वजनिक व्यापार पूल",
        },
        "m2bp12": {
            "zhcn": "可提審任意交易池進入官方白名單",
            "en": "Any trading pool can be submitted to the official\n whitelist",
            "hi": "किसी भी ट्रेडिंग पूल को आधिकारिक\nश्वेतसूची में जमा किया जा सकता है",
        },
        "m2bp21": {
            "zhcn": "參與BBZ治理",
            "en": "Participate in BBZ governance",
            "hi": "BBZ शासन में भाग लें",
        },
        "m2bp22": {
            "zhcn": "可參與投票決定BBZ平台收益的分配方式",
            "en": "Participate in voting to determine the distribution\n method of BBZ platform revenue",
            "hi": "BBZ प्लेटफ़ॉर्म राजस्व की वितरण विधि\n निर्धारित करने के लिए मतदान में भाग लें",
        }
    },
    "newhome":{
        "p1": {
            "zhcn": "我們致力於利用期權的獨特屬性，\n降低加密貨幣的鏈上連環清算風險。",
            "en": "We strive to capitalize on the unique properties of options,\nReduce the risk of serial liquidation on the chain of cryptocurrency.",
            "hi": "हम विकल्पों के अद्वितीय गुणों का लाभ उठाने का प्रयास करते हैं, \nक्रिप्टोकरेंसी की श्रृंखला पर क्रमिक परिसमापन के जोखिम को कम करते हैं।",
        },
        "p2": {
            "zhcn": "兩種期權創造了我們的交易系統",
            "en": "Two options create our trading system",
            "hi": "दो विकल्प हमारी ट्रेडिंग प्रणाली बनाते हैं",
        },
        "p3": {
            "zhcn": "簡易期權",
            "en": "Exotic Options",
            "hi": "विदेशी विकल्प",
        },
        "p4": {
            "zhcn": "這是一款入門型的期權產品。預測未來加密货币的價格漲跌来完成交易，也可以自主添加任何資產，為整個公鏈上的任意資產提供特殊流動性。",
            "en": "This is an entry-level options product. Predict the future price rise and fall of cryptocurrency to complete the transaction, and you can also add any assets independently to provide special liquidity for any asset on the entire public chain.",
            "hi": "यह एक प्रवेश स्तर का विकल्प उत्पाद है। लेनदेन को पूरा करने के लिए क्रिप्टोकरेंसी की भविष्य की कीमत में वृद्धि और गिरावट की भविष्यवाणी करें, और आप संपूर्ण सार्वजनिक श्रृंखला पर किसी भी संपत्ति के लिए विशेष तरलता प्रदान करने के लिए स्वतंत्र रूप से कोई भी संपत्ति जोड़ सकते हैं।",
        },
        "p5": {
            "zhcn": "全民莊家",
            "en": "All people bookmakers",
            "hi": "सभी लोग सट्टेबाज हैं", 
        },
        "p6": {
            "zhcn": "任何人都可以擁有莊家優勢。",
            "en": "Anyone can have the house advantage.",
            "hi": "मकान का लाभ किसी को भी हो सकता है।",
        },
        "p7": {
            "zhcn": "完全公開透明",
            "en": "Completely open and transparent",
            "hi": "बिल्कुल खुला और पारदर्शी",
        },
        "p8": {
            "zhcn": "所有存款、取款、交易都在區塊鏈上完成。",
            "en": "All deposits, withdrawals, transactions \nare done on the blockchain.",
            "hi": "सभी जमा, निकासी, लेनदेन ब्लॉकचेन पर किए जाते हैं।",
        },
        "p9": {
            "zhcn": "自主添加代幣",
            "en": "Add tokens independently",
            "hi": "स्वतंत्र रूप से टोकन जोड़ें",
        },
        "p10": {
            "zhcn": "任何你喜歡的代幣，都可以添加成為交易標的。",
            "en": "Any token you like can be added as a transaction target.",
            "hi": "आपकी पसंद का कोई भी टोकन लेनदेन लक्ष्य के रूप में जोड़ा जा सकता है।",
        },
        "p11": {
            "zhcn": "簡單無滑點",
            "en": "Simple and no slippage",
            "hi": "सरल और कोई फिसलन नहीं",
        },
        "p12": {
            "zhcn": "只需選擇向上/向下即可交易任何市場的短期價格波動。",
            "en": "Trade short-term price swings in any market by simply selecting Up/Down.",
            "hi": "केवल ऊपर/नीचे का चयन करके किसी भी बाजार में अल्पकालिक मूल्य उतार-चढ़ाव का व्यापार करें।",
        },
        "p13": {
            "zhcn": "底層邏輯依然是期權，我們稱它為 balancer buffer，簡稱BB。 BB外在的產品形式是藉貸，目標是解決整個加密貨幣生態的連環清算問題。[即將推出]",
            "en": "[Coming soon] The underlying logic is still options, we call it balancer buffer, or BB for short. The external product form of BB is lending, and the goal is to solve the serial liquidation problem of the entire cryptocurrency ecosystem.",
            "hi": "[जल्द ही आ रहा है] अंतर्निहित तर्क अभी भी विकल्प है, हम इसे बैलेंसर बफर या संक्षेप में BB कहते हैं। BB का बाहरी उत्पाद रूप ऋण देना है, और लक्ष्य संपूर्ण क्रिप्टोकरेंसी पारिस्थितिकी तंत्र की क्रमिक परिसमापन समस्या को हल करना है।",
        },
        "p14": {
            "zhcn": "了解更多",
            "en": "Learn More",
            "hi": "और अधिक जानें",
        },
        "p15": {
            "zhcn": "安全可靠",
            "en": "Safe and reliable",
            "hi": "सुरक्षित और विश्वसनीय", 
        },
        "p16": {
            "zhcn": "借貸過程中不會因為質押物的價格波動而被強制清算",
            "en": "During the loan process, it will not be forced to liquidate due to the price fluctuation of the collateral.",
            "hi": "ऋण प्रक्रिया के दौरान, संपार्श्विक की कीमत में उतार-चढ़ाव के कारण इसे समाप्त करने के लिए मजबूर नहीं किया जाएगा।", 
        },
        "p17": {
            "zhcn": "折扣優惠",
            "en": "Discount",
            "hi": "छूट",
        },
        "p18": {
            "zhcn": "以折扣價買入BTC/ETH",
            "en": "Buy BTC/ETH at a discount",
            "hi": "छूट पर BTC/ETH खरीदें",
        },
        "p19": {
            "zhcn": "超額收益",
            "en": "Excess Income",
            "hi": "अतिरिक्त आय",
        },
        "p20": {
            "zhcn": "流動性提供者可獲得超額收益",
            "en": "Liquidity providers can obtain excess returns",
            "hi": "तरलता प्रदाता अतिरिक्त रिटर्न प्राप्त कर सकते हैं",
        },
        "p21": {
            "zhcn": "多元化市場",
            "en": "Diversified markets",
            "hi": "विविध बाज़ार",
        },
        "p22": {
            "zhcn": "完全鏈上解決加密貨幣生態的連環清算問題。",
            "en": "Completely solve the serial liquidation problem of the cryptocurrency ecology on the chain.",
            "hi": "श्रृंखला पर क्रिप्टोकरेंसी पारिस्थितिकी की क्रमिक परिसमापन समस्या को पूरी तरह से हल करें।",
        },
        "p23": {
            "zhcn": "Balancer Buffer",
            "en": "Balancer Buffer",
            "hi": "बैलेंसर बफर",
        },
        "hzTitle": {
            "zhcn": "支持者",
            "en": "Supporter",
            "hi": "समर्थक",
        },
        "hz1": {
            "zhcn": "MoHash",
            "en": "MoHash",
            "hi": "MoHash",
        },
        "hz2": {
            "zhcn": "Coinbase Ventures",
            "en": "Coinbase Ventures",
            "hi": "Coinbase Ventures",
        },
        "hz3": {
            "zhcn": "LedgerPrime",
            "en": "LedgerPrime",
            "hi": "LedgerPrime",
        },
        "hz4": {
            "zhcn": "紅杉資本",
            "en": "Sequoia",
            "hi": "Sequoia",
        },
        "hz5": {
            "zhcn": "Coinswitch",
            "en": "Coinswitch",
            "hi": "Coinswitch",
        },
        "hz6": {
            "zhcn": "Quona Capital",
            "en": "Quona Capital",
            "hi": "Quona Capital",
        }
    },
    "newbb":{
        "p1": {
            "zhcn": "Balancer Buffer",
            "en": "Balancer Buffer",
            "hi": "बैलेंसर बफर",
        },
        "p2": {
            "zhcn": "我們稱它為 balancer buffer，簡稱BB。 BB外在的產品形式是藉貸，目標是解決整個加密貨幣生態的連環清算問題。",
            "en": "We call it balancer buffer, or BB for short. The external product form of BB is lending, and the goal is to solve the serial liquidation problem of the entire cryptocurrency ecosystem.",
            "hi": "हम इसे बैलेंसर बफ़र, या संक्षेप में BB कहते हैं। BB का बाहरी उत्पाद रूप उधार देना है, और लक्ष्य संपूर्ण क्रिप्टोकरेंसी पारिस्थितिकी तंत्र की क्रमिक परिसमापन समस्या को हल करना है।",
        },
        "p3": {
            "zhcn": "定投資金",
            "en": "Fixed Investment",
            "hi": "निश्चित निवेश",
        },
        "p4": {
            "zhcn": "這類資金是加密貨幣市場特有的資金，某些資金會長期的買入主流加密資產，而並不關心價格，如果我們可以創造一種打折價成功買入的產品。",
            "en": "This type of funds is unique to the cryptocurrency market. Some funds will buy mainstream encrypted assets for a long time without caring about the price. If we can create a product that can be successfully bought at a discount.",
            "hi": "इस प्रकार का फंड क्रिप्टोक्यूरेंसी बाजार के लिए अद्वितीय है। कुछ फंड कीमत की परवाह किए बिना लंबे समय के लिए मुख्यधारा की एन्क्रिप्टेड संपत्ति खरीदेंगे। यदि हम एक ऐसा उत्पाद बना सकें जिसे छूट पर सफलतापूर्वक खरीदा जा सके।",
        },
        "p5": {
            "zhcn": "借貸",
            "en": "Borrow Money",
            "hi": "पैसे उधार लो",
        },
        "p6": {
            "zhcn": "我們將提供借款人無法拒絕的條件，即“借貸中不會清算”的借貸產品。",
            "en": "We will offer loan products that borrowers cannot refuse, that is, ‘no liquidation in the loan’.",
            "hi": "हम ऐसे ऋण उत्पाद पेश करेंगे जिन्हें उधारकर्ता अस्वीकार नहीं कर सकते, यानी 'ऋण में कोई परिसमापन नहीं'।",
        },
        "p7": {
            "zhcn": "為什麼選擇Balancer Buffer",
            "en": "Why Choose Balancer Buffer",
            "hi": "बैलेंसर बफ़र क्यों चुनें?",
        },
        "p8": {
            "zhcn": "安全可靠",
            "en": "Safe and reliable",
            "hi": "सुरक्षित और विश्वसनीय",
        },
        "p81": {
            "zhcn": "借貸過程中不會因為質押物的價格波動而被強制清算",
            "en": "During the loan process, it will not be forced to liquidate due to the price fluctuation of the collateral.",
            "hi": "ऋण प्रक्रिया के दौरान, संपार्श्विक की कीमत में उतार-चढ़ाव के कारण इसे समाप्त करने के लिए मजबूर नहीं किया जाएगा।",
        },
        "p9": {
            "zhcn": "折扣優惠",
            "en": "Discount",
            "hi": "छूट",
        },
        "p91": {
            "zhcn": "以折扣價買入BTC/ETH",
            "en": "Buy BTC/ETH at a discount.",
            "hi": "छूट पर BTC/ETH खरीदें।",
        },
        "p10": {
            "zhcn": "超額收益",
            "en": "Excess Income",
            "hi": "अतिरिक्त आय",
         
        },
        "p101": {
            "zhcn": "流動性提供者可獲得超額收益",
            "en": "Liquidity providers can obtain excess returns.",
            "hi": "तरलता प्रदाता अतिरिक्त रिटर्न प्राप्त कर सकते हैं।",
        },
        "p1z": {
            "zhcn": "多元化市場",
            "en": "Siverse markets",
            "hi": "विविध बाज़ार",
        },
        "p1z1": {
            "zhcn": "完全鏈上解決整個加密貨幣生態的連環清算問題。",
            "en": "Completely solve the serial liquidation problem of the entire cryptocurrency ecology on the chain.",
            "hi": "श्रृंखला पर संपूर्ण क्रिप्टोकरेंसी पारिस्थितिकी की क्रमिक परिसमापन समस्या को पूरी तरह से हल करें।",
        },
        "p11": {
            "zhcn": "可靠且強大的交易平台",
            "en": "Reliable and powerful trading platform",
            "hi": "विश्वसनीय और शक्तिशाली ट्रेडिंग प्लेटफॉर्म",
        }, 
        "p12": {
            "zhcn": "目标是解决整个加密货币生态的连环清算问题。",
            "en": "The goal is to solve the serial liquidation problem of the entire cryptocurrency ecosystem.",
            "hi": "लक्ष्य संपूर्ण क्रिप्टोकरेंसी पारिस्थितिकी तंत्र की क्रमिक परिसमापन समस्या को हल करना है।",
        },
        "p13": {
            "zhcn": "定投資金",
            "en": "Fixed Investment",
            "hi": "निश्चित निवेश",
        },
        "p14": {
            "zhcn": "这类资金是加密货币市场特有的资金，某些资金会长期的买入主流加密资产，而并不关心价格，如果我们可以创造一种打折价成功买入的产品。将会非常有吸引力，我们会给与这部分资金特殊的待遇，打折购买加密货币，比如BTC和ETH，不论是否购买成功，都可以获得超高的年化利率，大约10-50%（本质上是期权费用）。",
            "en": "This type of funds is unique to the cryptocurrency market. Some funds will buy mainstream encrypted assets for a long time without caring about the price. If we can create a product that can be successfully bought at a discount. It will be very attractive, we will give special treatment to this part of the funds, buy cryptocurrencies at a discount, such as BTC and ETH, regardless of whether the purchase is successful, you can get a super high annualized interest rate, about 10-50% (essentially above is the option fee).",
            "hi": "इस प्रकार का फंड क्रिप्टोक्यूरेंसी बाजार के लिए अद्वितीय है। कुछ फंड कीमत की परवाह किए बिना लंबे समय के लिए मुख्यधारा की एन्क्रिप्टेड संपत्ति खरीदेंगे। यदि हम एक ऐसा उत्पाद बना सकें जिसे छूट पर सफलतापूर्वक खरीदा जा सके। यह बहुत आकर्षक होगा, हम फंड के इस हिस्से को विशेष उपचार देंगे, छूट पर क्रिप्टोकरेंसी खरीदें, जैसे कि BTC और ETH, भले ही खरीदारी सफल हो या नहीं, आप एक सुपर उच्च वार्षिक ब्याज दर प्राप्त कर सकते हैं, लगभग 10 -50% (अनिवार्य रूप से ऊपर विकल्प शुल्क है)।",
        },
        "p15": {
            "zhcn": "借貸",
            "en": "Borrow Money",
            "hi": "पैसे उधार लो",
        },
        "p16": {
            "zhcn": "我們將提供借款人無法拒絕的條件，即“借貸中不會清算”的借貸產品。這將再次增強BTC和ETH的魅力，而且更重要的是，防止市場的踩踏和連環清算。質押用戶需要付出只是短期較高的年化利率。假設是年化25%借款利率的產品，如果質押人只打算借兩週的話，絕對利息也只有1%，相比於潛在的強制清算風險，這是一個有可能值得付出的成本。",
            "en": "We will offer loan products that borrowers cannot refuse, that is, ‘no liquidation in the loan’. This will again enhance the allure of BTC and ETH, and more importantly, prevent market stampedes and serial liquidations. Pledge users need to pay only a short-term higher annualized interest rate. Assuming that it is a product with an annualized borrowing rate of 25%, if the pledger only intends to borrow for two weeks, the absolute interest is only 1%. Compared with the potential risk of forced liquidation, this is a cost that may be worth paying.",
            "hi": "हम ऐसे ऋण उत्पाद पेश करेंगे जिन्हें उधारकर्ता अस्वीकार नहीं कर सकते, यानी 'ऋण में कोई परिसमापन नहीं'। यह फिर से BTC और ETH के आकर्षण को बढ़ाएगा, और इससे भी महत्वपूर्ण बात यह है कि बाजार में भगदड़ और सिलसिलेवार परिसमापन को रोका जा सकेगा। गिरवी उपयोगकर्ताओं को केवल अल्पकालिक उच्च वार्षिक ब्याज दर का भुगतान करना होगा। यह मानते हुए कि यह 25% की वार्षिक उधार दर वाला एक उत्पाद है, यदि गिरवीकर्ता केवल दो सप्ताह के लिए उधार लेने का इरादा रखता है, तो पूर्ण ब्याज केवल 1% है। जबरन परिसमापन के संभावित जोखिम की तुलना में, यह एक ऐसी लागत है जो भुगतान करने लायक हो सकती है।",
        },
        "p17": {
            "zhcn": "即將推出",
            "en": "Coming Soon",
            "hi": "जल्द आ रहा है",
        }
    },
    "party":{
        "p1": {
            "zhcn": "用戶體驗活動\n賺加密貨幣",
            "en": "User Experience Event\nEarn Cryptocurrency",
            "hi": "उपयोगकर्ता अनुभव इवेंट\nक्रिप्टोकरेंसी अर्जित करें",
        },
        "p2": {
            "zhcn": "只需幾步，即可獲得活動獎勵",
            "en": "In just a few steps, you can get event rewards",
            "hi": "बस कुछ ही चरणों में, आप ईवेंट पुरस्कार प्राप्त कर सकते हैं",
        },
        "p3": {
            "zhcn": "活動介紹",
            "en": "Event Description",
            "hi": "घटना विवरण",
        },
        "p4": {
            "zhcn": "BBZ是一個特殊的期權交易平台，旨在利用期權的獨特屬性來降低加密貨幣的鏈上清算風險。 BBZ beta 是只對有限數量的用戶開放的私人內部測試，加入白名單是唯一的加入方式。",
            "en": "BBZ is a special options trading platform that aims to reduce the on-chain liquidation risk of cryptocurrencies by utilizing the unique properties of options. BBZ beta is a private internal test that is only open to a limited number of users, and being on the whitelist is the only way to join.",
            "hi": "BBZ एक विशेष विकल्प ट्रेडिंग प्लेटफ़ॉर्म है जिसका उद्देश्य विकल्पों के अद्वितीय गुणों का उपयोग करके क्रिप्टोकरेंसी के ऑन-चेन परिसमापन जोखिम को कम करना है। BBZ बीटा एक निजी आंतरिक परीक्षण है जो केवल सीमित संख्या में उपयोगकर्ताओं के लिए खुला है, और श्वेतसूची में होना ही शामिल होने का एकमात्र तरीका है।",
        },
        "p5": {
            "zhcn": "活動獎勵",
            "en": "Event Award",
            "hi": "इवेंट पुरस्कार",
        },
        "p6": {
            "zhcn": "為了鼓勵產品反饋以進行改進，我們通過以下方式激勵我們的早期支持者：",
            "en": "To encourage product feedback for improvement, we incentivize our early supporters in the following ways:",
            "hi": "सुधार के लिए उत्पाद फीडबैक को प्रोत्साहित करने के लिए, हम अपने शुरुआती समर्थकों को निम्नलिखित तरीकों से प्रोत्साहित करते हैं:",
        },
        "p7": {
            "zhcn": "搶先體驗 BBZ 上的特殊期權交易",
            "en": "Early access to exotic option trading on BBZ",
            "hi": "BBZ पर विदेशी विकल्प ट्रेडिंग तक शीघ्र पहुंच",
        },
        "p8": {
            "zhcn": "未來的獎勵，包括可能的空投獎勵",
            "en": "Future rewards, including possible airdrop rewards",
            "hi": "संभावित एयरड्रॉप पुरस्कारों सहित भविष्य के पुरस्कार",
        },
        "p9": {
            "zhcn": "頭部挖礦機會",
            "en": "Opportunity for head mining",
            "hi": "हेड माइनिंग का अवसर",
        },
        "p10": {
            "zhcn": "產品體驗步驟",
            "en": "BBZ Beta-test Steps:",
            "hi": "BBZ बीटा-परीक्षण चरण:",
        },
        "p11": {
            "zhcn": "領取測試幣",
            "en": "Claim testnet tokens",
            "hi": "टेस्टनेट टोकन का दावा करें",
        },
        "p12": {
            "zhcn": "交易和質押",
            "en": "Trade and Staking",
            "hi": "व्यापार और दांव",
        },
        "p13": {
            "zhcn": "創建新交易池",
            "en": "Create a new Trade pool",
            "hi": "एक नया ट्रेड पूल बनाएं",
        },
        "p14": {
            "zhcn": "關注社交平台",
            "en": "Follow social platforms",
            "hi": "सामाजिक मंचों का अनुसरण करें",
        },
        "p15": {
            "zhcn": "立即前往",
            "en": "Go now",
            "hi": "अब जाओ",
        },
        "p16": {
            "zhcn": "活動參與平台",
            "en": "Participation Platforms",
            "hi": "भागीदारी मंच",
        }
    },
    "dctc":{
        "p1": {
            "zhcn": "驗證您的 BBZ 白名單，以獲得更多獎勵！",
            "en": "Verify your BBZ whitelist for even more rewards!",
            "hi": "और भी अधिक पुरस्कारों के लिए अपनी BBZ श्वेतसूची सत्यापित करें!",
        },
        "p2": {
            "zhcn": "驗證",
            "en": "Verify",
            "hi": "सत्यापित करना",
        },
        "p3": {
            "zhcn": "驗證您的 BBZ 白名單",
            "en": "Verify your BBZ whitelist",
            "hi": "अपनी BBZ श्वेतसूची सत्यापित करें",
        },
        "p4": {
            "zhcn": "歡迎您體驗產品，驗證白名單，以獲取更多的獎勵！",
            "en": "You are welcome to experience the product and verify the whitelist to get more rewards!",
            "hi": "उत्पाद का अनुभव लेने और अधिक पुरस्कार पाने के लिए श्वेतसूची को सत्यापित करने के लिए आपका स्वागत है!",
        },
        "p5": {
            "zhcn": "驗證白名單",
            "en": "Verify Whitelist",
            "hi": "श्वेतसूची सत्यापित करें",
        },
    }

}


export default local