import React from 'react';
import PrivateRoute from '../components/PrivateRoute'
import {Routes, Route } from "react-router-dom";
import history from './browserHistory'
import routerListH5 from './routerListH5'


const BasicRouteH5 = () => (
    <Routes history={history} basename='/'>
        {
            routerListH5.map((routerItem,index)=>{
                return <Route exact key={'router' + index} path={"/" + routerItem.path} element={<routerItem.component/>} />
            })
        }
    </Routes>
);

export default BasicRouteH5;

