import './index.less';
import { Carousel } from 'antd';
import { useNavigate } from "react-router-dom";
import 'swiper/less'
import local from '../../unilt/local'
import { useSelector, useDispatch } from 'react-redux'
import Api from '../../unilt/api'

import homem1i1 from '../../static/homem1i1.png'
import homem1i2 from '../../static/homem1i2.png'
import homem1i3 from '../../static/homem1i3.png'
import arbitrum from '../../static/arbitrum.png'
import db1ico from '../../static/db1ico.png'
import db2ico from '../../static/db2ico.png'
import home2 from '../../static/home2.png'
import arb from '../../static/arb.png'
import bsc from '../../static/bsc.png'
import base from '../../static/base.png'



import p11 from '../../static/11.png'
import p12 from '../../static/12.png'
import p13 from '../../static/13.png'
import p14 from '../../static/14.png'

import p21 from '../../static/21.png'
import p22 from '../../static/22.png'
import p23 from '../../static/23.png'
import p24 from '../../static/24.png'

import p1gb from '../../static/p1gb.png'


import bnb from '../../static/bnb.png'
import btc from '../../static/btc.png'
import eth from '../../static/eth.png'
import uni from '../../static/uni.png'
import usdc from '../../static/usdc.png'
import usdt from '../../static/usdt.png'


import hz1 from '../../static/hz1.png'
import hz2 from '../../static/hz2.png'
import hz3 from '../../static/hz3.png'
import hz4 from '../../static/hz4.png'
import hz5 from '../../static/hz5.png'
import hz6 from '../../static/hz6.png'

import liwu from '../../static/hd/liwu.png'


function Home() {

  const navigate = useNavigate()
  const language = useSelector(state => state.language)


  const goPay = ((usrl = '') => {
    window.open(`${Api.topUrl}?lang=${language}${usrl}`, "_blank",);
  })


  const goPay11 = ((usrl = '') => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/balancerbuffer')
  })


  const goCl = (() => {
  })

  const goPay3 = (() => {
    window.open("https://opensea.io", "_blank",);
  })

  const goPay2 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/nft')
  })

  const goPay4 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/docs?id=5')
  })


  const ggg = ((e) => {
    var t = document.getElementById("hero-image")
      , n = t.getBoundingClientRect()
      , r = n.width / 2
      , o = (n.height,
        n.left + n.width / 2)
      , i = (e.clientX - o) / 100
      , a = 30 * (r - e.clientY) / r;
    a > 10 ? a = 10 : a < -10 && (a = -10);
    var s = "rotateX(".concat(a, "deg) rotateY(").concat(i, "deg)");
    t.style.transform = s
  })

  const goPage7 = (() => {
    window.open(`${Api.topUrl}/airdrop?lang=${language}`, "_blank",);
  })


  return (
    <div className="homeh5" onClick={() => goCl()}>
      <div className='mode1'>
        <div className='cont' onMouseMove={(e) => ggg(e)}>
          <pre className='p1'>{local['home']['m1p1'][language]}</pre>
          <pre className='p2'>{local['newhome']['p1'][language]}</pre>
          <img src={p1gb} className='bg111' id="hero-image" ></img>
          <img src={bnb} className='bnb'></img>
          <img src={btc} className='btc'></img>
          <img src={eth} className='eth'></img>
          <img src={uni} className='uni'></img>
          <img src={usdc} className='usdc'></img>
          <img src={usdt} className='usdt'></img>

          <div className='btnbox'>
            <div className='btn1' onClick={() => goPage7()}>{local['home']['p106'][language]} <img className='ico' src={liwu}></img></div>
            <div className='btn' onClick={() => goPay()}>{local['home']['m1b1'][language]}</div>
          </div>

          
          <pre className='p3'>{local['newhome']['p2'][language]}</pre>
          <div className='newbox'>
            <div className='leftbox l1'>
              <pre className='lp1'>{local['newhome']['p3'][language]}</pre>
              <pre className='lp2'>{local['newhome']['p4'][language]}</pre>
              <div className='lbtn' onClick={() => goPay()}>{local['home']['m1b1'][language]}</div>
            </div>
            <div className='rightbox'>
              <div className='itembox'>
                <img className='itemico' src={p11} />
                <pre className='itemp1'>{local['newhome']['p5'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p6'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p12} />
                <pre className='itemp1'>{local['newhome']['p7'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p8'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p13} />
                <pre className='itemp1'>{local['newhome']['p9'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p10'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p14} />
                <pre className='itemp1'>{local['newhome']['p11'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p12'][language]}</pre>
              </div>
            </div>
          </div>
          <div className='newbox'>
            <div className='leftbox l2'>
              <pre className='lp1'>{local['newhome']['p23'][language]}</pre>
              <pre className='lp2'>{local['newhome']['p13'][language]}</pre>
              <div className='lbtn1' onClick={() => goPay11()}>{local['newhome']['p14'][language]}</div>
            </div>
            <div className='rightbox'>
              <div className='itembox'>
                <img className='itemico' src={p21} />
                <pre className='itemp1'>{local['newhome']['p15'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p16'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p22} />
                <pre className='itemp1'>{local['newhome']['p17'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p18'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p23} />
                <pre className='itemp1'>{local['newhome']['p19'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p20'][language]}</pre>
              </div>
              <div className='itembox'>
                <img className='itemico' src={p24} />
                <pre className='itemp1'>{local['newhome']['p21'][language]}</pre>
                <pre className='itemp2'>{local['newhome']['p22'][language]}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mode2'>
        <div className='cont'>
          <div className='left'>
            <pre className='p1'>{local['home']['m2p1'][language]}</pre>
            <pre className='p2'>{local['home']['m2p2'][language]}</pre>
            <div className='btn'>{local['home']['m2b2'][language]}</div>
          </div>
          <img className='right' src={home2}></img>
        </div>
      </div>
      {/* <div className='mode3'>
        <div className='cont'>
          <pre className='p1'>{local['home']['m3p1'][language]}</pre>
          <div className='box'>
            <div className='item'>
              <div className='icobox'>
                <img className='ico' src={db1ico}></img>
                <div className='icp1'>{local['home']['m3icop1'][language]}</div>
              </div>
              <pre className='ip1'>{local['home']['m3icop1t1'][language]}</pre>
              <div className='btn_box'>
                <div className='btn1' style={{visibility: 'hidden'}} onClick={() => goPay3()}>{local['home']['m3icop1b1'][language]}</div>
                <div className='btn2' onClick={() => goPay2()}>{local['home']['m3icop1b2'][language]}</div>
              </div>
            </div>
            <div className='item'>
              <div className='icobox'>
                <img className='ico' src={db2ico}></img>
                <div className='icp1'>{local['home']['m3icop2'][language]}</div>
              </div>
              <pre className='ip1'>{local['home']['m3icop2t1'][language]}</pre>
              <div className='btn_box'>
                <div className='btn1' style={{visibility: 'hidden'}}>{local['home']['m3icop1b1'][language]}</div>
                <div className='btn2' onClick={() => goPay4()}>{local['home']['m3icop1b2'][language]}</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='mode3'>
        <div className='cont'>
          <pre className='p1'>{local['home']['m3p1'][language]}</pre>
          <div className='box'>
            <div className='item'>
              <div className='icobox'>
                <img className='ico' src={base}></img>
                <div className='icp1'>BASE</div>
              </div>
              <div className='btn1' onClick={() => goPay('&net=base')}>{local['home']['m3icop1b1'][language]}</div>
            </div>
            <div className='item'>
              <div className='icobox'>
                <img className='ico' src={bsc}></img>
                <div className='icp1'>BNB Chain</div>
              </div>
              <div className='btn1' onClick={() => goPay('&net=bsc')}>{local['home']['m3icop1b1'][language]}</div>
            </div>
            {/* <div className='item'>
              <div className='icobox'>
                <img className='ico' src={arb}></img>
                <div className='icp1'>{local['home']['m3icop1'][language]}</div>
              </div>
              <div className='btn1' onClick={() => goPay('&net=arbitrum')}>{local['home']['m3icop1b1'][language]}</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='mode4'>
        <div className='cont'>
          <pre className='p1'>{local['newhome']['hzTitle'][language]}</pre>
          <div className='box'>
            <div className='item'>
              <img src={hz2} className='ico' />
              <div className='pss'>{local['newhome']['hz2'][language]}</div>
            </div>
            <div className='item'>
              <img src={hz3} className='ico' />
              <div className='pss'>{local['newhome']['hz3'][language]}</div>
            </div>
            <div className='item'>
              <img src={hz5} className='ico' />
              <div className='pss'>{local['newhome']['hz5'][language]}</div>
            </div>
            <div className='item'>
              <img src={hz6} className='ico' />
              <div className='pss'>{local['newhome']['hz6'][language]}</div>
            </div>
            <div className='item'>
              <img src={hz1} className='ico' />
              <div className='pss'>{local['newhome']['hz1'][language]}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
