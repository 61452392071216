import Home from  '../pages/homeH5';
import Nft from '../pages/ntfH5';
import Party from '../pages/partyH5';

const routerListH5 = [
    {
        path: '',
        breadcrumbName:  '首页',
        component: Home,
        private: false
    },
    {
        path: '/balancerbuffer',
        breadcrumbName:  'NFT',
        component: Nft,
        private: false
    },
    {
        path: '/event',
        breadcrumbName:  'Party',
        component: Party,
        private: false
    }
]

export default routerListH5;