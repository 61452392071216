import { SETUSER, SETLANGUAGE, SETASSETS, SETBBZPRICE, SETBTCPRICE, SETASSETSTYPE, SETETHPRICE, SETACTIVELIST } from '../constants/counter'


const INITIAL_STATE = {
  user: sessionStorage.getItem('user') ? sessionStorage.getItem('user') : false,
  language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
  assets: localStorage.getItem('assets') ? localStorage.getItem('assets') : false,
  bbzPrice: localStorage.getItem('bbzPrice') ? localStorage.getItem('bbzPrice') : false,
  btcPrice: localStorage.getItem('btcPrice') ? localStorage.getItem('btcPrice') : false,
  ethPrice: localStorage.getItem('ethPrice') ? localStorage.getItem('ethPrice') : false,
  assetsType: localStorage.getItem('assetsType') ? localStorage.getItem('assetsType') : 'real',
  activelist: localStorage.getItem('activelist') ? localStorage.getItem('activelist') : []
}


export default function counter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SETUSER:
      return {
        ...state,
        user: action.user
      }
      break;
    case SETLANGUAGE:
      return {
        ...state,
        language: action.language
      }
      break;
    case SETASSETS:
      return {
        ...state,
        assets: action.assets
      }
      break;
    case SETBBZPRICE:
      return {
        ...state,
        bbzPrice: action.bbzPrice
      }
      break;
    case SETBTCPRICE:
      return {
        ...state,
        btcPrice: action.btcPrice
      }
      break;
    case SETETHPRICE:
      return {
        ...state,
        ethPrice: action.ethPrice
      }
      break;
    case SETASSETSTYPE:
      return {
        ...state,
        assetsType: action.assetsType
      }
      break;
      case SETACTIVELIST:
      return {
        ...state,
        activelist: action.activelist
      }
      break;
    default:
      return state
  }
}
