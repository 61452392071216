import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import './index.less';
import toplogo from '../../static/toplogo.png'
import emitter from '../../unilt/emit'
import checkYs from '../../static/checkYs.png'
import local from '../../unilt/local'
import close from '../../static/close.png'
import liwu from '../../static/hd/liwu.png'
import Api from '../../unilt/api'


function TopMode() {
  let [menu1State, setmenu1State] = useState(false)
  let [loginShow, setloginShow] = useState(false)
  let [ys, setys] = useState(false)
  const user = useSelector(state => state.user)
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const renderRef = useRef(true)



  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      emitter.addListener("callMe", () => {
      });
      emitter.addListener("hiddentc", () => {
      });
      return
    }
  }, [])



  const goPay = (() => {
    window.open(`${Api.topUrl}?lang=${language}`, "_blank",);
  })


  const hiddenMenuttc = (() => {
    setmenu1State(false)
    setys(false)
  })



  const goPage1 = (() => {
    window.open(`${Api.topUrl}`, "_blank",)
  })

  const goPage2 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/balancerbuffer')
  })

  const goPage3 = (() => {
    if (language === 'zhcn') {
      window.open('https://bbz-trade.gitbook.io/bbz/')
    } else if (language === 'en') {
      window.open('https://bbz-trade.gitbook.io/bbz-1/')
    } else{
      window.open('https://bbz-trade.gitbook.io/bbz-1/')
    }
  })


  const goPage4 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/event')
  })



  const goPageTop = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/')
  })


  const handleChange = (value) => {
    dispatch({ type: 'SETLANGUAGE', language: value })
    setys(false)
  };


  const goLiwu = (()=>{
    emitter.emit("opHdbox","Hello")
  })

  return (
    <div className="topbox">
      <img className='logo' src={toplogo} onClick={() => goPageTop()} />
      <ul className='nav'>
        <li className='item' onClick={() => goPage1()}>{local["topMode"]["nav1"][language]}</li>
        <li className='item' onClick={() => goPage2()}>{local["topMode"]["nav2"][language]}</li>
        <li className='item' onClick={() => goPage3()}>{local["topMode"]["nav3"][language]}</li>
        {/* <li className='item' onClick={() => goPage4()}>{local["topMode"]["nav4"][language]}<img className='liwuitem' src={liwu} /></li> */}
        
      </ul>
      <div className='actionbox'>
        <div className='btnGo' onClick={() => goPay()}>{local["topMode"]["btn1"][language]}</div>
        <div className='btnLogin' onClick={() => setys(true)}>
          <img className='ico' src={checkYs} />
        {language === 'zhcn' && '繁體中文'}{language === 'en' && 'English'}{language === 'hi' && 'हिंदी'}
        </div>
        {
          ys && <div className='langboxmask'>
            <div className='langBox' >
              <div className='title'>{local["topMode"]["boxtitle1"][language]}</div>
              <img className='closeico' src={close} onClick={() => setys(false)}></img>
              <div className={language === 'en' ? 'langitem active' : 'langitem'} onClick={() => handleChange('en')}>English</div>
              <div className={language === 'zhcn' ? 'langitem active' : 'langitem'} onClick={() => handleChange('zhcn')}>繁體中文</div>
              <div className={language === 'hi' ? 'langitem active' : 'langitem'} onClick={() => handleChange('hi')}>हिंदी</div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default TopMode;
